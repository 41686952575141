import React, { FC, useEffect, useState } from "react"

import {
  HeartOutlined,
  LoadingOutlined,
  SelectOutlined
} from "@ant-design/icons"
import { Button, Collapse, Space } from "antd"
import Table, { ColumnsType } from "antd/es/table"

import EditModal from "./EditModal"
import { SourceView, StatusView } from "./ExtraComponents"
import FilterTable from "./FilterTable"
import PhotoStatusButton from "./PhotoStatusButton"
import { adsApi } from "../../../api/endpoints/adsApi"
import { demoRole, staffRole, staffSingle, staffSingleAgency } from "../../../constants/roles"
import { useActions } from "../../../hooks/useActions"
import { useAllowedRoles } from "../../../hooks/useAllowedRoles"
import { useTypedSelector } from "../../../hooks/useTypedSelector"
import { IAdsListPayload } from "../../../types/ads"
import { cleanObject } from "../../../utils/data"
import { showErrorNotification, showSuccessNotification } from "../../../utils/ui"
import AdsBanners from "../../ui/banners/AdsBanners"
import MetroView from "../../ui/metro-view/MetroView"

const AdsPage: FC = () => {
  const isUser = useAllowedRoles([ staffSingle, staffSingleAgency, staffRole, demoRole ])

  const [ editOpen, setEditOpen ] = useState(false)
  const [ editId, setEditId ] = useState(0)

  const { isFetchAds } = useTypedSelector((state) => state.app)
  const { isLoading, isFetching, tableData, tableMetaData, bannerData, tableFilters } = useTypedSelector((state) => state.ads)
  const {
    adsFetchData,
    setAdsFetching,
    setAppFetchAds,
    adsSendCall
  } = useActions()

  const staffColumns: ColumnsType<any> = [
    {
      title: "Дата и время",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Заголовок",
      dataIndex: "title",
      key: "title",
      render: (value, record) => (
        <Space direction="horizontal">
          <PhotoStatusButton status={record.photo_status} onProcessPhoto={() => {
              adsApi.processPhoto({ source_id:record.source.id,item_id:record.id })
                  .then((response) => {
                    if (response.status === 200) {
                      showSuccessNotification("Задача на обработку фото успешно поставлена.")
                      setAppFetchAds(true)
                    }
                  })
                  .catch(() => {
                    showErrorNotification("Ошибка при отправке данных на сервер")
                  })

          }} onDownloadPhoto={()=>{
            adsApi.downloadPhoto({ source_id: record.source.id, item_id: record.id })
                .catch(() => {
                  showErrorNotification("Ошибка при скачивании")
                })


          }} />
          <Button
            size="small"
            type={record.favorites ? "primary" : "default"}
            icon={<HeartOutlined />}
            style={{ marginRight: 5 }}
            onClick={() => {
              if (record.favorites) {
                adsApi.deleteFavoritesAd(record.id)
                  .then((response) => {
                    if (response.status === 200) {
                      showSuccessNotification("Объявление удалено из избранного")
                      setAppFetchAds(true)
                    }
                  })
                  .catch(() => {
                    showErrorNotification("Ошибка при удалении из избранного, повтороите попытку")
                  })
              } else {
                adsApi.addFavoritesAd(record.id)
                  .then((response) => {
                    if (response.status === 200) {
                      showSuccessNotification("Объявление добавлено в избранное")
                      setAppFetchAds(true)
                    }
                  })
                  .catch(() => {
                    showErrorNotification("Ошибка при добавлении в избранное, повтороите попытку")
                  })
              }
            }}
          />

          <a href={value.link} target="_blank">
            <Button
              size="small"
              icon={<SelectOutlined />}
              style={{ marginRight: 5 }}
            />
          </a>

          <span>
            {value.name}
          </span>
        </Space>
      )
    },
    {
      title: "Источник",
      dataIndex: "source",
      key: "source",
      render: (value) => {
        return value && (
          <>
            <SourceView source={value.name} />
            {/*{*/}
            {/*  isAdmin &&*/}
            {/*    <Button*/}
            {/*      size="small"*/}
            {/*      icon={<StopOutlined />}*/}
            {/*      disabled*/}
            {/*      onClick={() => {*/}
            {/*        adsAddToBlackList(record.id, value.id, () => {*/}
            {/*          showSuccessNotification("Объявление добавлено в ЧС")*/}
            {/*        })*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      В ЧС*/}
            {/*    </Button>*/}
            {/*}*/}
          </>
        )
      }
    },
    {
      title: "Стоимость",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Адрес",
      dataIndex: "address",
      key: "address",
      render: (value) => (
        <>
          <div>{value.name}</div>
          <MetroView
            title={value.metro.name}
            color={`#${value.metro.color}`}
          />
        </>
      )
    },
    {
      title: "Контакт",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Ответственный",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (value, record) => value && (
        <StatusView
          status={value}
          id={record.id}
          setEditOpen={setEditOpen}
          setEditId={setEditId}
          sendCall={adsSendCall}
        />
      )
    },
  ]

  const fetchData = (paginationData = { page: 1, pageSize: 10, sorting: { name: "ASC" } }, withouLoading = false, onFinish?: () => void) => {
    const tempFilters = {
      data: (tableFilters.data?.start && tableFilters.data?.start) ? tableFilters.data : null,
      phone: tableFilters.phone || null,
      user_id: (tableFilters.user_id && tableFilters.user_id !== 0) ? tableFilters.user_id : null,
      status_id: (tableFilters.status_id && tableFilters.status_id !== 0) ? tableFilters.status_id : null,
      metro_id: (tableFilters.metro_id && tableFilters.metro_id !== 0) ? tableFilters.metro_id : null,
      source_id: (tableFilters.source_id && tableFilters.source_id !== 0) ? tableFilters.source_id : null,
      group_id: (tableFilters.group_id && tableFilters.group_id !== 0) ? tableFilters.group_id : null,
      room_id: (tableFilters.room_id && tableFilters.room_id !== 0) ? tableFilters.room_id : null,
      item_id: tableFilters.item_id || null,
      price_start: tableFilters.price_start || null,
      price_end: tableFilters.price_end || null
    }

    const data: IAdsListPayload = {
      page: paginationData.page,
      pageSize: paginationData.pageSize,
      sorting: paginationData.sorting,
      filters: cleanObject(tempFilters)
    }

    adsFetchData(data, withouLoading, onFinish)
  }

  const bindTableRequest = (paginationData = { page: 1, pageSize: 10, sorting: { name: "ASC" } }) => {
    fetchData(paginationData)
  }

  useEffect(() => {
    bindTableRequest()
  }, [])

  useEffect(() => {
    if (isFetching) {
      bindTableRequest()
      setAdsFetching(false)
    }
  }, [ isFetching ])

  useEffect(() => {
    if (isFetchAds) {
      bindTableRequest({
        page: tableMetaData.currentPage,
        pageSize: tableMetaData.pageSize,
        sorting: { name: "ASC" }
      })
      setAppFetchAds(false)
    }
  }, [ isFetchAds ])

  return (
    <div style={{ marginBottom: 150 }} className="page-content">
      <AdsBanners
        bannerData={bannerData}
        isLoading={isLoading}
        showFull={isUser}
      />

      <Collapse style={{ marginTop: 20 }}>
        <Collapse.Panel header="Фильтр объявлений" key="1">
          <FilterTable isLoading={isLoading} />
        </Collapse.Panel>
      </Collapse>

      <Table
        dataSource={tableData || []}
        columns={staffColumns}
        style={{ margin: "24px 0" }}
        scroll={{ x: 870 }}
        rowClassName={(record) => record.removed ? "table__row_red" : ""}
        loading={{
          spinning: isLoading,
          indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
        }}
        pagination={{
          position: [ "bottomLeft" ],
          showSizeChanger: true,
          pageSizeOptions: [ "10", "25", "50", "100" ],
          defaultCurrent: 1,
          defaultPageSize: 10,
          current: tableMetaData?.currentPage || 1,
          total: tableMetaData.total,
          onChange: (page, per_page) => bindTableRequest({
            page: page,
            pageSize: per_page,
            sorting: { name: "ASC" }
          }),
          onShowSizeChange: (page, per_page) => bindTableRequest({
            page: page,
            pageSize: per_page,
            sorting: { name: "ASC" }
          }),
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} из ${total} объявлений`,
        }}
        size="small"
        bordered
      />

      <EditModal
        open={editOpen}
        setOpen={setEditOpen}
        id={editId}
      />
    </div>
  )
}

export default AdsPage
