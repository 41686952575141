import React, { FC, useEffect, useState } from "react"

import {
  BellOutlined,
  DatabaseOutlined, GlobalOutlined, PhoneOutlined, QuestionCircleOutlined, SendOutlined,
  StopOutlined, UserOutlined
} from "@ant-design/icons"
import { Alert, Avatar, Badge, Button, Checkbox, Col, Dropdown, Layout, Menu, Modal, Row, Space } from "antd"
import { Link } from "react-router-dom"

import styles from "./Header.module.scss"
import { superAdminRole } from "../../constants/roles"
import { routeNames } from "../../constants/routes"
import { useActions } from "../../hooks/useActions"
import { useAllowedRoles } from "../../hooks/useAllowedRoles"
import { useTypedSelector } from "../../hooks/useTypedSelector"
import { updateArrayItem } from "../../utils/data"
import { showSuccessNotification } from "../../utils/ui"
import TelegramModal from "../telegram-modal/TelegramModal"

const Header: FC = () => {
  const isSuperAdmin = useAllowedRoles([ superAdminRole ])

  const [ modalOpen, setModalOpen ] = useState(false)
  const [ tgModalOpen, setTgModalOpen ] = useState(false)
  const [ checkedList, setCheckedList ] = useState<{id: number, status: boolean, name: string}[]>([])

  const {
    name,
    user_locations_list,
    user_phone_status,
    user_app_status,
    locationsLoading,
    access
  } = useTypedSelector((state) => state.currentUser)
  const { currentUserGetLocations, currentUserUpdatePhoneStatus, currentUserUpdateLocations } = useActions()

  useEffect(() => {
    if (modalOpen) {
      currentUserGetLocations()
    }
  }, [ modalOpen ])

  useEffect(() => {
    setCheckedList(user_locations_list)
  }, [ user_locations_list ])

  const userStatusMenuItems = [
    {
      key: "1",
      label: (
        <a onClick={() => currentUserUpdatePhoneStatus(4)}>
          <StopOutlined style={{ color: "red" }} /> Не беспокоить
        </a>
      )
    },
    {
      key: "2",
      label: (
        <a onClick={() => currentUserUpdatePhoneStatus(2)}>
          <UserOutlined style={{ color: "green" }} /> Готов
        </a>
      )
    },
  ]

  const helpItems = [
    {
      key: "1",
      label: (
        <a href="https://t.me/firstcall_support" target="_blank">
          <SendOutlined /> Написать в поддержку
        </a>
      )
    },
    // {
    //   key: "2",
    //   label: (
    //     <a href="https://t.me/callfirst" target="_blank">
    //       <SendOutlined /> Телеграм канал
    //     </a>
    //   )
    // },
  ]

  return (
    <Layout.Header
      className={styles.headerContainer}
    >
      <div className={styles.logoWrapper}>
        <Link to={routeNames.home}>
          <img src="/logo.svg" alt="FIRST CALL" className="main-logo" />
        </Link>
      </div>

      <div className={styles.headerContent}>
        <Space direction={"horizontal"}>
          <Dropdown
            overlay={<Menu items={helpItems}/>}
            placement="bottomLeft"
            trigger={[ "click" ]}
          >
            <Button
              type="text"
              icon={<QuestionCircleOutlined />}
            >
              Помощь
            </Button>
          </Dropdown>

          <Button
            type="text"
            icon={<BellOutlined />}
            onClick={() => setTgModalOpen(true)}
          >
            Уведомления в Telegram
          </Button>
        </Space>

        <Space direction={"horizontal"}>
          {!isSuperAdmin && (
            <div className={styles.licenceInfo}>
              <p>Доступ до {access?.date || "-"}</p>
              <p>Осталось {access?.day || "0"} дней</p>
            </div>
          )}

          <div className={styles.userInfo}>
            <Avatar size={28} icon={<UserOutlined />} />
            <span>
              {name || "Пользователь"}
            </span>
          </div>

          {
            isSuperAdmin ? (
              <Alert message="Суперадмин" type="info" />
            ) : (
              <>
                <Button
                  type="text"
                  icon={<DatabaseOutlined />}
                  onClick={() => setModalOpen(true)}
                />

                <Badge dot style={{ backgroundColor: user_app_status === 2 ? "#52c41a" : "red" }}>
                  <Button
                    type="text"
                    icon={<GlobalOutlined />}
                  />
                </Badge>

                <Dropdown
                  overlay={<Menu items={userStatusMenuItems}/>}
                  placement="bottomRight"
                  trigger={[ "click" ]}
                >
                  <Badge dot style={{ backgroundColor: user_phone_status === 2 ? "#52c41a" : "red" }}>
                    <Button
                      type="text"
                      icon={<PhoneOutlined />}
                    />
                  </Badge>
                </Dropdown>
              </>
            )
          }
        </Space>
      </div>

      <TelegramModal open={tgModalOpen} setOpen={setTgModalOpen} />

      <Modal
        title="Выбор локаций"
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        onOk={() => {
          currentUserUpdateLocations(checkedList, () => {
            showSuccessNotification("Выбранные локации обновлены")
            setModalOpen(false)
          }, () => null)
        }}
        okText="Сохранить"
        okButtonProps={{ loading: locationsLoading }}
      >
        <Space direction="vertical">
          <Space>
            <Button
              size="small"
              onClick={() => {
                setCheckedList(
                  checkedList.map((item) => ({
                    ...item,
                    status: true
                  }))
                )
              }}
            >
              Выбрать все
            </Button>

            <Button
              size="small"
              onClick={() => {
                setCheckedList(
                  checkedList.map((item) => ({
                    ...item,
                    status: false
                  }))
                )
              }}
            >
              Отчистить все
            </Button>
          </Space>

          <div style={{ width: "100%", display: "flex", flexDirection: "column", margin: "5px 2px" }}>
            {
              checkedList.map((item, index) => (
                <Row>
                  <Col>
                    <span key={index} onClick={() => {
                      let newList = updateArrayItem(item.id, { status: !item.status }, checkedList)
                      setCheckedList(newList)
                    }}>
                      <Checkbox checked={item.status}>
                        {item.name}
                      </Checkbox>
                    </span>
                  </Col>
                </Row>
              ))
            }
          </div>
        </Space>
      </Modal>
    </Layout.Header>
  )
}

export default Header
